import { GlobalComponentsContext } from "@/core/contexts";
import { Backdrop, CircularProgress, DialogTitle, Dialog, Typography, Button } from "@mui/material";
import { useReader } from "gesti";
import { FC, ReactNode, useContext, useEffect, useRef, useState, isValidElement } from "react";
import CoreBox from "../widgets/core-box";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import screenFullLoading from "@/core/utils/loading";

const WithGlobalComponents: FC<{
    children: ReactNode
}> = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [dialog, setDialog] = useState<boolean>(false);
    const [dialogProps, setDialogProps] = useState<DialogComponentProps<ReactNode | DialogDefaultProps>>();
    const value: GlobalComponentsContextValue = {
        loading: {
            showLoading: () => {
                setLoading(true);
                return Promise.resolve(true);
            },
            hideLoading: () => {
                setLoading(false);
                return Promise.resolve(false);
            }
        },
        dialog: {
            showDialog<T = DialogDefaultProps>(child: T, close = true) {
                setDialogProps({
                    child: child as any,
                    close,
                });
                return Promise.resolve(true);
            },
            hideDialog: () => {
                setDialog(false);
                return Promise.resolve(false);
            }
        }
    };

    screenFullLoading.subScribe(() => {
        setLoading(screenFullLoading.isLoading);
    });

    useEffect(() => {
        if (!dialog) setDialog(true);
        //不允许监听 dialog
    }, [dialogProps]);

    return <GlobalComponentsContext.Provider value={value}>
        <div id="_notification-root">
            <ToastContainer
                position="bottom-center"
                autoClose={2000}
            />
            <div>
                <CustomLoading loading={loading} />
                <CustomDialog dialog={dialog} dialogProps={dialogProps} />
                {children}
            </div>
        </div>

    </GlobalComponentsContext.Provider>
}


const CustomDialog: FC<{ dialog: boolean, dialogProps: MayUndefined<DialogComponentProps<ReactNode | DialogDefaultProps>> }> = ({
    dialog,
    dialogProps,
}) => {
    const context = useContext(GlobalComponentsContext);

    if (!dialogProps) return <></>

    const { child, close } = dialogProps;

    const handleClose = () => {
        if (close) context?.dialog.hideDialog();
    }
    if (isValidElement(child)) {
        return <Dialog open={dialog} onClose={handleClose}>
            {child}
        </Dialog>
    }
    const content: DialogDefaultProps = child as DialogDefaultProps;
    const {cancel=true}=content;
    return <Dialog open={dialog} onClose={handleClose}>
        <div className="max-sm:max-w-[16rem] sm:max-w-[20rem]">
            <CoreBox>
                <div>
                    <div className="w-full text-center pb-2">
                        <Typography variant="h6" fontWeight={"bold"}>
                            {content.title}
                        </Typography>
                    </div>
                    <Typography component="p" color="text.secondary">
                        {content.content}
                    </Typography>
                    {
                        content.contentNode
                    }
                    <div className="flex mt-2 justify-between gap-2">
                        {
                            cancel && <Button onClick={
                                () => {
                                    content?.onCancel?.();
                                    handleClose();
                                }
                            } variant="outlined" className="flex-1" sx={{
                                borderRadius: "10rem"
                            }}>{
                                    content.cancelText ?? "取消"
                                }</Button>
                        }
                        <Button
                            onClick={
                                () => {
                                    content?.onConfirm?.();
                                    handleClose();
                                }
                            }
                            variant="contained" className="flex-1 bg-gradient-to-r from-secondary to-primary text-center" sx={{
                                borderRadius: "10rem"
                            }}>
                            <div className="text-dark-text dark:text-text ">
                                {
                                    content.confirmText ?? "确定"
                                }
                            </div>
                        </Button>
                    </div>
                </div>
            </CoreBox>
        </div>
    </Dialog>

}


const CustomLoading: FC<{ loading: boolean }> = ({ loading }) => {
    const context = useContext(GlobalComponentsContext);
    const loadingTimer = useRef<any>(0);
    const timeout = Number(process.env.LOADING_TIMEOUT_SECOND ?? 10);
    useEffect(() => {
        if (loading) {
            loadingTimer.current = setTimeout(() => {
                context!.loading.hideLoading();
            }, 1000 * timeout)
        } else {
            if (loadingTimer.current) clearTimeout(loadingTimer.current);
        }
    }, [context, loading, timeout]);
    return <Backdrop open={loading} sx={{
        zIndex: 10000,
        color:"transparent",
        backgroundColor:"transparent"
    }}>
        <CircularProgress color="primary" />
    </Backdrop>;
}
export default WithGlobalComponents;