import { Button } from "@mui/material";
import { FC } from "react";

/**
 *  引导浏览器打开链接悬浮图层
 */
const GuideToBrowser: FC<{
  onClose: () => void;
  open: boolean;
}> = ({ onClose, open }) => {
  if (!open) return <></>;
  return (
    <div className="w-screen h-screen overflow-hidden fixed top-0 left-0 bg-black/70 z-50">
      <div className="absolute right-0 size-28 top-10">
        <img src="/static/svgs/arrow.svg" alt="箭头" />
      </div>
      <div className="text-center text-white p-4 pt-48">
        <div className="text-xl  font-bold">
          去常青藤网上药店APP继续完成操作吧
        </div>
      </div>
      <div className="flex gap-2 justify-center">
        <div className="size-10 bg-white rounded-md p-2">
          <img src="/static/svgs/we-chat-browser.svg" alt="地球" />
        </div>
        <div className="text-white text-sm">
          <div>1.点击右上角"..."</div>
          <div>
            2.选择<span className="text-error">在浏览器中打开</span>
          </div>
        </div>
      </div>
      <div className="flex justify-center p-4">
        <Button
          variant="outlined"
          style={{
            borderColor: "white",
          }}
          size="large"
          onClick={onClose}
        >
          <div className="text-white">关闭</div>
        </Button>
      </div>
    </div>
  );
};

export default GuideToBrowser;
