// import { BasePageOption } from "@/types";
import { Box, Button } from "@mui/material";
import MyAppBar from "./components/app-bar";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { handleOpenIvyphaAPP, isWeChat } from "@/core/utils";
import { WebViewInAppPageMessageModel } from "@/types/model/web-in-app";
import { useState } from "react";
import GuideToBrowser from "@/common/components/guide-to-browser";

export const BasePage: React.FC<BasePageOption> = ({
  body,
  children,
  appBar,
  disableAppBar,
  requireAPP = false,
}) => {
  const location = useLocation();
  const [search, _] = useSearchParams(location.search);
  const [openGuide, setOpenGuide] = useState<boolean>(false);
  const platform: string = search.get("platform") || "web";

  const userAgent = navigator?.userAgent ?? "";

  if (requireAPP && !/IVYPHA_APP_WEBVIEW_AGENT/.test(userAgent)) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <span className="text-lg">
            请在<span className="text-primary">常青藤网上药店APP</span>
            内打开此页面
          </span>
        </div>
        <div className="p-2">
          <Button
            variant="contained"
            onClick={() => {
              if (isWeChat()) {
                setOpenGuide(true);
                return;
              }
              const messageModel: WebViewInAppPageMessageModel = {
                name: "nativeRouter",
                message: "nativeRouter",
                timestamp: 0,
                data: {
                  data: {
                    url: window.location.href,
                  },
                  type: "push",
                  path: "/web-view-in-app",
                },
              };
             
              handleOpenIvyphaAPP(JSON.stringify(messageModel));
            }}
          >
            <span className="text-white">去打开</span>
          </Button>
        </div>
        <GuideToBrowser
          open={openGuide}
          onClose={() => {
            setOpenGuide(false);
          }}
        />
      </Box>
    );
  }
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Box className="base-page-wrapper">
          {!disableAppBar && platform === "web" && <MyAppBar {...appBar} />}
          {(body || children) && (
            <Box className="base-page-body-wrapper" bgcolor="secondary.light">
              {body || children}
            </Box>
          )}
          {!body && !children && (
            <>
              <div className="w-full h-full bg-background dark:bg-dark-background"></div>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
