import React from "react";
import lazyLoad from "../utils/lazy-load";

export default [
  {
    path: "/web-in-app",
    children: [
      {
        path: "/web-in-app/app-update-version",
        element: lazyLoad(
          React.lazy(() => import("@/views/web-view-in-app/app-update-version"))
        ),
        meta: {
          requiresAuth: false,
          title: "应用更新",
        },
      },
      {
        path: "/web-in-app/mission-center",
        element: lazyLoad(
          React.lazy(
            () =>
              import("@/views/web-view-in-app/mission-center/mission-center")
          )
        ),
        meta: {
          requiresAuth: false,
          title: "任务中心",
        },
      },
      {
        path: "/web-in-app/integral-record",
        element: lazyLoad(
          React.lazy(() => import("@/views/web-view-in-app/integral-record"))
        ),
        meta: {
          requiresAuth: false,
          title: "小藤币获取记录",
        },
      },
    ],
  },
] as Array<RouteObject>;
