import { WebViewInAppChannelModel, WebViewInAppChannelRouterModel, WebViewInAppMessageModel } from "@/types/model/web-in-app";

//持久化工具
export const storageUtils = {
  getItem(key: string) {
    return localStorage.getItem(key);
  },
  addItem(key: string, value: string) {
    localStorage.setItem(key, value);
  },
  removeItem(key: string) {
    localStorage.removeItem(key);
  },
  clean() {
    localStorage.clear();
  },
};

/**
 * 格式化金额单位
 */
export const formatPrice = (value: number) => {
  return (value * 0.01).toFixed(2);
};

/**
 * 防抖
 */
export const deBounce = (callback: VoidFunction, delay: number) => {
  let timer: any;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      callback.apply(null);
      timer = null;
    }, delay);
  };
};

/**
 * 节流
 */
export const throttle = (callback: VoidFunction, delay: number) => {
  let preCall: number = +new Date();
  return function () {
    const currentTime: number = +new Date();
    if (currentTime - preCall >= delay) {
      preCall = currentTime;
      callback.apply(null);
    }
  };
};

/**
 * 通过类型判断是否处方药
 * @param prescriptionType 处方类型
 * @returns
 */
export const checkIsRxByPrescriptionType = (
  prescriptionType: string | number
): boolean => {
  const isRx: boolean =
    Number(prescriptionType) === 4 || Number(prescriptionType) === 5;
  return isRx;
};

/**
 * 打开常青藤网上药店APP
 */
export const handleOpenIvyphaAPP = (data?: string, tk?: string) => {
  let deepLink = `${process.env.REACT_APP_OPEN_IVYPHA_SCHEME}`;
  if (data) deepLink += `?data=${data}`;
  if (tk) deepLink += `&tk=${tk}`;

  window.open(deepLink);
  setTimeout(() => {
    window.open(
      process.env.REACT_APP_DOWNLOAD_IVYPHA_URL,
    );
  }, 3000);
};


/**
 * # 是否是常青藤网上药店APP内打开的页面
 * @returns 
 */
export const isApp = () => {
  return /^IVYPHA_APP_WEBVIEW_AGENT/.test(navigator?.userAgent);
}

// 是否微信内
export const isWeChat = () => {
  var ua = navigator.userAgent.toLowerCase();
  return /micromessenger/.test(ua);
}

/**
 * 将 @type {Date} 转换为 YYYY年MM月DD日 HH:mm:ss 格式的字符串
 */
export const prettyDate = (date?: Date): Nullable<string> => {
  if (!date) return null;

  if (typeof date === "string" || date instanceof String || typeof date === "number") {
    date = new Date(date)
  }
  const year = date.getFullYear().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${year}年${month}月${day} ${hours}:${minutes}:${seconds}`;
};